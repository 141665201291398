// export const BASEURL = process.env.VUE_APP_BASEURL;
// export const FILESURL = process.env.VUE_APP_FILESURL;
// // export const FILESURL = 'https://api.aliff.in';
export const RAZORPAY_KEY_ID = process.env.VUE_APP_RAZORPAY_KEY_ID;

// export const API_VERSION = "v1.0";
//Live
// export const BASEURL = "https://api.aliff.in/api";
// export const FILESURL = "https://api.aliff.in";
// export const DASHBOARD_BASEURL = "https://app.aliff.in/";

// DEV
// export const BASEURL = "http://localhost:8002/api";
// export const FILESURL = "http://localhost:8002";
export const API_VERSION = "v1.0";

//Staging

// export const BASEURL = "https://api-alif.binarynumbers.io/api";
// export const IMAGEURL = "https://api-alif.binarynumbers.io";
// //export const DASHBOARD_REGISTER_URL = "https://www.app.aliff.in/register"
// //export const DASHBOARD_LOGIN_URL = "https://www.app.aliff.in"
// export const DASHBOARD_REGISTER_URL =
//   "https://student-dashboard-alif.binarynumbers.io/register";
// export const DASHBOARD_LOGIN_URL =
//   "https://student-dashboard-alif.binarynumbers.io";

export const BASEURL = "https://api-away.enpointe.io/api";
export const IMAGEURL = "https://api-away.enpointe.io/";
export const DASHBOARD_REGISTER_URL = "https://api-away.enpointe.io/register";
export const DASHBOARD_LOGIN_URL = "https://api-away.enpointe.io/";
