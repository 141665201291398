import Vue from "vue"
import axios from "axios";
import { BASEURL } from "@/config/index";
import { TokenService } from "./storageService";

var apiLoading = null;

export default () => {

    const instance = axios.create({
        baseURL: BASEURL
    });

    const AUTH_TOKEN = TokenService.getToken();
    if (AUTH_TOKEN) {
        instance.defaults.headers.common["authorization"] = `${AUTH_TOKEN}`;
    }

    instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    instance.interceptors.request.use((req) => {
        if (!req.url.includes('/common/new-notifications-messages') &&
            !req.url.includes('/common/chats/message') &&
            !req.url.includes('/common/student-application-chat') &&
            !req.url.includes('/student/course/application/chat')
        ) {
            if (!apiLoading) apiLoading = Vue.$loading.show();
        }
        return req;
    });

    instance.interceptors.response.use(
        function (response) {
            if (apiLoading) { apiLoading.hide(); apiLoading = null; }
            // Do something with response data
            return response;
        },
        function (error) {
            if (apiLoading) { apiLoading.hide(); apiLoading = null; }
            // Do something with response error
            if (!error["response"]) {
                showErrorMessage("Your authorization token is invalid or expired");
                // redirect to auth page or login again
                window.location.replace("/");
                return Promise.reject(error);
            } else if (error.response.status == 403) {
                showErrorMessage("Your authorization token is invalid or expired");
                // redirect to auth page or login again
                window.location.replace("/");
            } else if (error.response.status == 401) {
                // store.dispatch("Account/onLogoutUser")
                window.location.replace("/");
                showErrorMessage(
                    "Something went wrong. kindly contact your administrator"
                );
            } else {
                // showErrorMessage(
                //   "Something went wrong. kindly contact your administrator"
                // );
            }
            return Promise.reject(error.response);
        }
    );
    return instance;
};

const showErrorMessage = message => {
    console.log('Error ', message);
    // notification.error({
    //   message: "Oops!",
    //   description: message
    // });
};