<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";

import { useWindowSize, useCssVar } from "@vueuse/core";

import store from "@/store";

import { TokenService } from "./apiServices/storageService";
import AuthServices from "./apiServices/AuthServices";
import CommonServices from "./apiServices/CommonServices";
import ToastificationContentVue from "./@core/components/toastification/ToastificationContent.vue";
import helpers from "@/libs/helpers";

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
  import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === "full") return "layout-full";
      return `layout-${this.contentLayoutType}`;
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type;
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = [
      "primary",
      "secondary",
      "success",
      "info",
      "warning",
      "danger",
      "light",
      "dark",
    ];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(
        `--${colors[i]}`,
        document.documentElement
      ).value.trim();
    }

    // Set Theme Breakpoints
    const breakpoints = ["xs", "sm", "md", "lg", "xl"];

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(
        useCssVar(
          `--breakpoint-${breakpoints[i]}`,
          document.documentElement
        ).value.slice(0, -2)
      );
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout;
    document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
  },
  setup() {
    const { skin, skinClasses } = useAppConfig();

    // If skin is dark when initialized => Add class to body
    if (skin.value === "dark") document.body.classList.add("dark-layout");

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: "Vue-Toastification__fade",
    });

    // Set Window Width in store
    store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
    const { width: windowWidth } = useWindowSize();
    watch(windowWidth, (val) => {
      store.commit("app/UPDATE_WINDOW_WIDTH", val);
    });

    return {
      skinClasses,
    };
  },
  methods: {
    async RefreshToken() {
      this.$store.commit("user/SET_USER_DETAILS", {});
      this.$store.commit("user/SET_USER_AUTHENTICATED", false);
      const token = TokenService.getToken();

      let payload = {};

      const location = await helpers.getLocation(navigator);

      if (location) {
        payload = {
          ...payload,
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
          accuracy: location.coords.accuracy,
        };
      }

      if (token) {
        AuthServices.getRefreshToken(payload)
          .then((response) => {
            if (response.data.status) {
              TokenService.saveToken(response.data.data.token);
              this.$store.commit(
                "user/SET_USER_DETAILS",
                response.data.data.user
              );
              this.$store.commit("user/SET_USER_AUTHENTICATED", true);
              const user = response.data.data.user;
              if (user.user_type === "student") {
              } else {
                this.$store.commit(
                  "verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED",
                  true
                );
                this.$store.dispatch("user/onGetAssignedStudents");
              }
              if (window.location.pathname === "/") {
                if (user.user_type === "student") {
                  this.$router.push("/home");
                } else {
                  this.$store.dispatch("user/onGetAssignedStudents");
                   if (
                    ["operations", "counselor", "visa"].includes(user.user_type)
                  ) {
                    this.$router.push("/staff_home");
                  } else if (["agent", "franchise"].includes(user.user_type)) {
                    this.$router.push("/agent_home");
                  } else {
                    this.$router.push("/student_list");
                  }
                }
              }
            } else {
              TokenService.removeToken();
            }
          })
          .catch((error) => {
            console.log("Error RefreshToken ", error);
            TokenService.removeToken();
          });
      }
    },
    async getNewNotificationsAndMessages() {
      let user_authenticated = this.$store.state.user.user_authenticated;
      if (user_authenticated) {
        try {
          const response =
            await CommonServices.getNewNotificationsAndMessages();
          if (response.data.status) {
            const {
              notifications,
              unread_notifications_count,
              unread_notifications,
              messages,
              unread_messages_count,
            } = response.data.data;

            for (let i = 0; i < messages.length; i++) {
              const message = messages[i];
              let text = `New Message from ${message.user_firstname} ${message.user_lastname}`;
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: text,
                  icon: "BellIcon",
                  variant: "success",
                },
              });
            }

            for (let j = 0; j < notifications.length; j++) {
              const notification = notifications[j];
              let text = `${notification.notification_heading}`;
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: text,
                  icon: "BellIcon",
                  variant: "success",
                },
              });
            }

            store.commit(
              "user/ON_SET_UNREAD_NOTIFICATIONS",
              unread_notifications
            );
            store.commit(
              "user/ON_SET_UNREAD_NOTIFICATIONS_COUNT",
              unread_notifications_count
            );
            store.commit(
              "user/ON_SET_UNREAD_MESSAGES_COUNT",
              unread_messages_count
            );
            // if (notifications.length > 0) {
            //   store.dispatch("user/onGetNotifications");
            // }
            // if (messages.length > 0) {
            //   store.dispatch("user/onGetChats");
            // }
          }
        } catch (err) {
          console.log("Error in getting getNewNotificationsAndMessages ", err);
        }
      }
    },
  },
  beforeMount() {
    this.RefreshToken();

    setInterval(() => {
      this.getNewNotificationsAndMessages();
    }, 10000);
  },
};
</script>
<style>
body {
  scroll-behavior: smooth;
}
</style>
