import moment from "moment";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {

    getDateTimeFormatted(date) {
        return moment(date).format("DD-MM-YYYY - hh:mm A");
    },

    showErrorToast(context, text) {
        context.$toast({
            component: ToastificationContentVue,
            props: {
                title: text,
                icon: 'EditIcon',
                variant: 'failure',
            }
        });
    },

    showSuccessToast(context, text) {
        context.$toast({
            component: ToastificationContentVue,
            props: {
                title: text,
                icon: 'EditIcon',
                variant: 'success',
            }
        });
    },

    formatIntake(intake) {
        if (intake && typeof intake === "string") {

            intake = intake.replace(/ /g, '');

            let split = " ";
            if (intake.includes(",")) {
                // return intake.split(",");
                split = ",";
            }
            if (intake.includes("/")) {
                split = "/";
            }
            return intake
                .replace(/ /g, "")
                .split(split)
                .map((month) => month.slice(0, 3))
                .join(", ");
        }
        return "";
    },

    formatIntakeForApplyModal(intake) {
        if (intake && typeof intake === "string") {

            intake = intake.replace(/ /g, '');

            if (intake.includes(",")) {
                return intake.split(",");
            }
            if (intake.includes("/")) {
                return intake.split("/");
            }
            return [intake]
        }
        return "";
    },

    getLocation(navigator) {

        return new Promise((resolve, reject) => {
            try {
                navigator.geolocation.getCurrentPosition(
                    // Success
                    (position) => {
                        resolve(position)
                    },
                    // Error
                    (error) => {
                        console.error('Error in navigator.geolocation.getCurrentPosition ', error)
                        resolve(null)
                    }
                );
            } catch (error) {
                console.error('Error in getLocation ', error)
                resolve(null)
            }

        })


    }

}